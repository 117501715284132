'use client';

import MedSimples from '@/components/TenantName/MedSimples';
import { Box, Flex, Link, Text } from '@medsimples/design-system/src/chakra';
import { Button } from '@medsimples/design-system/src/components';
import NextLink from 'next/link';
import { useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import { useDesignTokens } from '../../app/providers/design_tokens_provider';

interface Props {
  privacyPoliceUrl: string;
  content: string;
  cta: string;
}

export default function Landpage({ privacyPoliceUrl, content, cta }: Props) {
  const tokens = useDesignTokens();
  const [isLoading, setLoading] = useState(false);

  return (
    <Flex
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      h='100%'
    >
      <div
        // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
        dangerouslySetInnerHTML={{
          __html: sanitizeHtml(content, {
            allowedTags: ['img', 'div', 'span', 'br', 'p'],
            allowedAttributes: {
              img: ['src', 'align'],
              div: ['style'],
            },
          }),
        }}
      />

      <Box paddingY={4}>
        <Button
          isLoading={isLoading}
          colorScheme={tokens.button.primary.scheme}
          marginBottom={3}
          data-testid='button'
          as={NextLink}
          href={'/welcome'}
          onClick={() => setLoading(true)}
        >
          {cta}
        </Button>

        <Box textAlign='center' marginBottom={10}>
          <Text fontSize={8}>
            * Ao clicar em "vamos lá" você concorda com os{' '}
            <Link
              target='_blank'
              href={
                privacyPoliceUrl
                  ? privacyPoliceUrl
                  : 'https://static.medsimples.dev/medsimples_privacy_policy.pdf'
              }
            >
              <Text as='span' color={tokens.text.primary.color} fontSize={8}>
                termos de privacidade
              </Text>
            </Link>{' '}
            <MedSimples />.
          </Text>
        </Box>
      </Box>
    </Flex>
  );
}
